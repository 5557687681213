import { BinaryExpression, Expression, Identifier, Literal, LogicalExpression, UnaryExpression } from 'jsep';

export const getLiteralValue = (literalExpression: Literal): any => literalExpression.value

export const getNumericalValue = (
  expression: Expression,
  variableDict: Record<string, any>
): number => getValue(expression, variableDict) ?? 0;

export const getVariableValue = (identifier: Identifier, variableDict: Record<string, any>): any => variableDict[identifier.name]

export const getValue = (expression: Expression, variableDict: Record<string, any>): any => {
  if (isLiteral(expression)) {
    return getLiteralValue(expression)
  } else if (isIdentifier(expression)) {
    return getVariableValue(expression, variableDict)
  }
}

export const isConditionMet = (
  expression: Expression,
  variableDict: Record<string, any>
): boolean => {
  if (isUnaryExpression(expression)) {
    return isUnaryConditionMet(expression, variableDict);
  } else if (isBinaryExpression(expression)) {
    return isBinaryExpressionMet(expression, variableDict);
  } else if (isLogicalExpression(expression)) {
    return isLogicalExpressionMet(expression, variableDict)
  } else if (isLiteral(expression)) {
    return !!getLiteralValue(expression)
  } else if (isIdentifier(expression)) {
    return isIdentifierTruthy(expression, variableDict)
  } else {
    throw new Error(`Don't know how to check a condition of type ${expression.type}`)
  }
};

export const isBinaryExpressionMet = (
  expression: BinaryExpression,
  variableDict: Record<string, any>
): boolean => {
  switch (expression.operator) {
    case "===":
      return (
        getValue(expression.left, variableDict) ===
        getValue(expression.right, variableDict)
      );

    case "&&":
      return !!(
        isConditionMet(expression.left, variableDict) &&
        isConditionMet(expression.right, variableDict)
      );

    case ">":
      return (
        getNumericalValue(expression.left, variableDict) >
        getNumericalValue(expression.right, variableDict)
      );

    case ">=":
      return (
        getNumericalValue(expression.left, variableDict) >=
        getNumericalValue(expression.right, variableDict)
      );

    case "<":
      return (
        getNumericalValue(expression.left, variableDict) <
        getNumericalValue(expression.right, variableDict)
      );

    case "<=":
      return (
        getNumericalValue(expression.left, variableDict) <=
        getNumericalValue(expression.right, variableDict)
      );

    default:
      throw new Error(
        `Could not parse the binary operator ${expression.operator}`
      );
  }
};

export const isIdentifierTruthy = (
  expression: Identifier,
  variableDict: Record<string, any>
): boolean => {
  return !!variableDict[expression.name]
}

export const isLogicalExpressionMet = (
  expression: LogicalExpression,
  variableDict: Record<string, any>
): boolean => {
  switch (expression.operator) {
    case "&&":
      return (
        isIdentifierTruthy(expression.left as Identifier, variableDict) &&
        isIdentifierTruthy(expression.right as Identifier, variableDict)
      );

    default:
      throw new Error(
        `Could not parse the logical operator ${expression.operator}`
      );
  }
}

export const isUnaryConditionMet = (expression: UnaryExpression, variableDict: Record<string, any>): boolean => {
  switch (expression.operator) {
    case '!':
      const { argument: { name } } = expression
      if (typeof name === 'string') {
        return !variableDict[name]
      } else {
        throw new Error(`Can't use the ! operator on name ${name}`)
      }

    default:
      throw new Error(`Could not parse the unary operator ${expression.operator}`)
  }
}


export const isBinaryExpression = (expression: Expression): expression is BinaryExpression => expression.type === 'BinaryExpression';
export const isIdentifier = (expression: Expression): expression is Identifier => expression.type === 'Identifier';
export const isLiteral = (expression: Expression): expression is Literal => expression.type === 'Literal';
export const isLogicalExpression = (
  expression: Expression
): expression is LogicalExpression => expression.type === 'LogicalExpression';
export const isUnaryExpression = (expression: Expression): expression is UnaryExpression => expression.type === 'UnaryExpression';


