import Deck from './components/organisms/Deck'
import styles from "./App.module.css";
import { pick, sample } from 'lodash';
import { Textfit } from 'react-textfit'
import { CardData,CardType } from "./data/card.types";
import { bundle, useRiducer } from 'riduce';
import { Dimension, GameState, INITIAL_GAME_STATE, PlayerChoice } from './data/game.types';
import { computeVariableEffects, computeVariableGrowth, generateDummyDeck, pickNextCard, processCardLocking } from './data/deck-utils';
import DimensionIcons from './components/molecules/DimensionIcons';
import { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useState } from 'react';

const DUMMY_CARDS = generateDummyDeck(5)

export default function App() {
  const { state, dispatch, actions } = useRiducer(INITIAL_GAME_STATE);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad && (!state.topCard.data || !state.topCard.id)) {
      const gameStartCard =
        state.deck.dictionary["06a35883-9dc5-4aff-a46e-cbf12369454f"];
      dispatch(actions.topCard.create.assign({
        id: gameStartCard.id,
        data: gameStartCard
      }))
      setIsFirstLoad(false)
    } else if (!state.topCard.data) {
      setIsFirstLoad(true);
      dispatch(bundle([actions.create.reset(), actions.animations.hintRotation.create.off()]));
    }
  }, [isFirstLoad, setIsFirstLoad, dispatch, actions, state])

  const cardBearer = state.topCard.data?.type === CardType.ORDINARY
    ? state.topCard.data?.bearer[state.topCard.bearerIdx ?? 0]
    : undefined

  const activateTopCard = actions.topCard.isRotatable.create.on;
  const descendTopCard = actions.topCard.isDescended.create.on;
  const flipTopCard = actions.topCard.isFlipped.create.on;
  const refreshTopCard = (cardId: string) => actions.topCard.create.do((leaf, tree) => {
    const card = tree.deck.dictionary[cardId];
    const bearerIdx = sample(Object.keys(card.bearer))

    return {
      ...leaf,
      isFlipped: false,
      isRotatable: false,
      id: cardId,
      bearerIdx: bearerIdx ? parseInt(bearerIdx) : undefined
      }
  })
  
  const swipeCard = (card: CardData, playerChoice: PlayerChoice) =>
    actions.create.do((prevState) => {

      let variables = { ...prevState.variables };

      if (variables.game_over) {
        return {
          ...prevState,
          animations: {
            isFirstEnter: true,
            hintRotation: false
          },
          topCard: {
            data: undefined,
            isDescended: false,
            isFlipped: false,
            isRotatable: false
          }
        }
      }

      if (card.type === CardType.ORDINARY) {
        variables = computeVariableEffects(
          prevState.variables,
          card,
          playerChoice
        );
      }

      const deck: GameState["deck"] = processCardLocking(
        prevState.deck,
        card.id
      );

      const topCardData =
        pickNextCard(card, playerChoice, deck, variables);

      return {
        ...prevState,
        animations: {
          ...prevState.animations,
          hintRotation: false
        },
        deck,
        topCard: {
          data: topCardData,
          id: topCardData.id,
          isDescended: true,
          isFlipped: false,
          isRotatable: false,
        },
        variables:
          card.type === CardType.ORDINARY ? computeVariableGrowth(variables) : variables,
      };

    });

  const handleDescentComplete = (card: CardData, isTop: boolean) => {
    if (isTop) {
      dispatch(descendTopCard());
    }

    if (state.animations.isFirstEnter) {
      dispatch(actions.animations.isFirstEnter.create.off());
    }
  }

  const handleExitRest = (card: CardData, choice: PlayerChoice) => {
    dispatch(swipeCard(card, choice))
  };

  const handleFlipComplete = () => dispatch(activateTopCard());

  useEffect(() => {
    if (!state.topCard.id && state.topCard.data) {
      dispatch(refreshTopCard(state.topCard.data.id));
    }

    // flip the top card if descended and not flipped
    if (state.topCard.isDescended && !state.topCard.isFlipped) {
      dispatch(flipTopCard());
    }
  });

  return (
    <>
      <DimensionIcons
        values={pick(state.variables, Object.values(Dimension))}
      />
      <div
        style={{
          height: "100px",
          flexDirection: "column",
          textAlign: "center",
          margin: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Flex
          height="100%"
          maxWidth="400px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Textfit max={18}>
            {state.topCard.isRotatable &&
              state.topCard.data?.type === CardType.ORDINARY &&
              state.topCard.data?.question}
          </Textfit>
        </Flex>
      </div>
      <div
        className={`flex fill center ${styles.container}`}
        style={{ overflow: "hidden" }}
      >
        <Deck
          cards={state.variables.game_over ? [] : DUMMY_CARDS}
          cardsDict={state.deck.dictionary}
          characters={state.characters}
          hintRotation={state.animations.hintRotation}
          isFirstEnter={state.animations.isFirstEnter}
          isGameOver={state.variables.game_over}
          onDescentComplete={handleDescentComplete}
          onExitRest={handleExitRest}
          onFlipComplete={handleFlipComplete}
          topCard={state.topCard}
        />
      </div>
      <Flex height="50px" padding="10px" justifyContent="center">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth="400px"
          textAlign="center"
          height="100%"
        >
          {state.topCard.isRotatable && cardBearer && state.characters[cardBearer] && (
            <>
              {state.characters[cardBearer].name && (
                <Textfit max={16}>
                  {state.characters[cardBearer].name}
                </Textfit>
              )}
              {state.characters[cardBearer].title && (
                <Textfit max={16}>
                  {state.characters[cardBearer].title}
                </Textfit>
              )}
            </>
          )}
        </Flex>
      </Flex>
      <div
        style={{
          height: "50px",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p style={{ maxWidth: "400px", textAlign: "center" }}></p>
      </div>
    </>
  );
}
