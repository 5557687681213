import { sample } from 'lodash'
import * as Avtr from '../utils/avataaar.type';
import { CharacterAppearance, CharacterParsed } from "./card.types";


interface CharacterGenerationOpts {
  seed?: number,
  randomizeRest?: boolean,
  pGlasses?: number,
  pFacialHair?: number,
  matchHairColours?: boolean
}

const defaultStyle: Required<CharacterAppearance> = {
  topType: Avtr.TopType.ShortHairShortWaved,
  accessoriesType: Avtr.AccessoriesType.Blank,
  hairColor: Avtr.HairColor.Brown,
  hatColor: Avtr.HatColor.PastelOrange,
  facialHairType: Avtr.FacialHairType.Blank,
  facialHairColor: Avtr.FacialHairColor.Brown,
  clotheType: Avtr.ClothesType.GraphicShirt,
  clotheColor: Avtr.ClothesColor.PastelBlue,
  graphicType: Avtr.GraphicType.Bat,
  eyeType: Avtr.EyesType.Default,
  eyebrowType: Avtr.EyebrowType.Default,
  mouthType: Avtr.MouthType.Default,
  skinColor: Avtr.SkinColor.Brown
}

export const getCharacterImage = (character: CharacterParsed, { pGlasses = 0.3, pFacialHair = 0.3, matchHairColours = true, randomizeRest = true }: CharacterGenerationOpts = {}): string => {
  
  const randomStyle = getRandomStyle({ pGlasses, pFacialHair, matchHairColours })

  const style: Partial<CharacterAppearance> = { ...randomizeRest ? randomStyle : defaultStyle, ...character.appearance }

  return avatarImgUrl(style);
}

export const avatarImgUrl = ({
  topType = defaultStyle.topType,
  accessoriesType = defaultStyle.accessoriesType,
  hatColor = defaultStyle.hatColor,
  hairColor = defaultStyle.hairColor,
  facialHairType = defaultStyle.facialHairType,
  clotheType = defaultStyle.clotheType,
  clotheColor = defaultStyle.clotheColor,
  graphicType = defaultStyle.graphicType,
  eyeType = defaultStyle.eyeType,
  eyebrowType = defaultStyle.eyebrowType,
  mouthType = defaultStyle.mouthType,
  skinColor = defaultStyle.skinColor
}: Partial<CharacterAppearance> = {}): string =>
  `https://avataaars.io/?avatarStyle=Transparent&topType=${topType}&accessoriesType=${accessoriesType}&hatColor=${hatColor}&hairColor=${hairColor}&facialHairType=${facialHairType}&clotheType=${clotheType}&clotheColor=${clotheColor}&graphicType=${graphicType}&eyeType=${eyeType}&eyebrowType=${eyebrowType}&mouthType=${mouthType}&skinColor=${skinColor}`;

export const getCharacterName = (character: CharacterParsed): string => {
  if (character.name && character.title) {
    return `${character.name}, ${character.title}`;
  }

  return character.name ?? character.title ?? ""
}

export const getRandomStyle = ({ pGlasses = 0.3, pFacialHair = 0.3, matchHairColours = true }: CharacterGenerationOpts) => {
  const nonWhite = (val: string) => val !== "White";
  const validClothesColours = Object.values(Avtr.ClothesColor).filter(nonWhite);
  const validHatColours = Object.values(Avtr.HatColor).filter(nonWhite);

  const topType = sample(Avtr.TopType)!;
  const glassesType = sample(Avtr.AccessoriesType)!;
  const hairColor = sample(Avtr.HairColor)!;
  const hatColor = sample(validHatColours)!;
  const facialHair = sample(Avtr.FacialHairType)!;
  const facialHairColorR = sample(Avtr.FacialHairColor)!;
  const clotheType = sample(Avtr.ClothesType)!;
  const clotheColor = sample(validClothesColours)!;
  const graphicType = sample(Avtr.GraphicType)!;
  const eyeType = sample(Avtr.EyesType)!;
  const eyebrowType = sample(Avtr.EyebrowType)!;
  const mouthType = sample(Avtr.MouthType)!;
  const skinColor = sample(Avtr.SkinColor)!;

  const accessoriesType: Avtr.AccessoriesType = Math.random() <= pGlasses ? glassesType : Avtr.AccessoriesType.Blank

  const facialHairType: Avtr.FacialHairType = Math.random() <= pFacialHair ? facialHair : Avtr.FacialHairType.Blank

  // @ts-ignore
  const facialHairColor: Avtr.FacialHairColor = matchHairColours
    ? hairColor
    : facialHairColorR;

  const randomStyle: Partial<CharacterAppearance> = {
    topType,
    accessoriesType,
    hairColor,
    hatColor,
    facialHairType,
    facialHairColor,
    clotheType,
    clotheColor,
    graphicType,
    eyeType,
    eyebrowType,
    mouthType,
    skinColor,
  };

  return randomStyle
}