import { animated, SpringValue } from "@react-spring/web";
import { CardParsed } from "../../data/card.types";
import { CARD_X_REMOVAL_THRESHOLD } from "./CardRotator";

interface Props {
  card: CardParsed;
  x: SpringValue<number>;
  rot: SpringValue<number>;
}

const CardRotatorText: React.FC<Props> = ({ card, x, rot }) => {
  return (
    <animated.div
      style={{
        height: "50px",
        display: "flex",
        padding: "10px",
        justifyContent: x.to((v) => (v < 0 ? "flex-end" : "flex-start")),
        transform: rot.to((v) => `perspective(200px) rotateZ(${-10 * v}deg)`),
      }}
    >
      <animated.p
        style={{
          color: "black",
          fontWeight: 900,
          display: x.to((v) => (v < 0 ? "block" : "none")),
          opacity: x.to((v) => Math.min(Math.abs(v) / CARD_X_REMOVAL_THRESHOLD, 1)),
          textAlign: "right",
          width: "80%",
        }}
      >
        {card.no?.override ?? "No"}
      </animated.p>
      <animated.p
        style={{
          color: "black",
          fontWeight: 900,
          display: x.to((v) => (v > 0 ? "block" : "none")),
          opacity: x.to((v) => Math.min(Math.abs(v) / CARD_X_REMOVAL_THRESHOLD, 1)),
          textAlign: "left",
          width: "80%",
        }}
      >
        {card.yes?.override ?? "Yes"}
      </animated.p>
    </animated.div>
  );
}

export default CardRotatorText