import { CardData, CardType, CharacterParsed } from "../../data/card.types";
import { CHARACTERS_DB } from "../../data/db";
import { PlayerChoice } from "../../data/game.types";
import AnswerRotator from "./AnswerRotator";
import CardDescender from "./CardDescender";
import CardFlipper from "./CardFlipper";
import CardRotator from "./CardRotator";


interface CardProps {
  animateDownWithIndex?: number;
  bearerIdx?: number;
  card: CardData;
  characters?: Record<string, CharacterParsed>;
  frontColor?: string;
  hintRotation?: boolean;
  isDescended: boolean;
  isFlipped: boolean;
  isRotatable: boolean;
  isTop: boolean;
  onDescentComplete?: (card: CardData, isTop: boolean) => void;
  onExitStart?: (card: CardData, playerChoice: PlayerChoice) => void;
  onExitRest?: (card: CardData, playerChoice: PlayerChoice) => void;
  onFlipComplete?: (card:CardData) => void;
  onReachTop?: (card: CardData) => void;
}


function Card({ animateDownWithIndex, bearerIdx, card, characters = CHARACTERS_DB, frontColor, hintRotation, isDescended, isFlipped, isRotatable, isTop, onDescentComplete, onExitStart, onExitRest, onFlipComplete, onReachTop }: CardProps): JSX.Element {

  if (!isTop || !isDescended) {
    return (
      <CardDescender
        {...{ animateDownWithIndex, card, isTop, onDescentComplete }}
      />
    );
  } else if (isRotatable) {
    return card.type === CardType.ORDINARY
      ? <CardRotator {...{ bearerIdx, card, characters, frontColor, hintRotation, isTop, onExitStart, onExitRest }} />
      : <AnswerRotator {...{ card, frontColor, isTop, onExitStart, onExitRest }} />;
  } else {
    return (
      <CardFlipper
        {...{ card, frontColor, isFlipped, isTop, onFlipComplete, onReachTop }}
      />
    );
  } 
}


export default Card;
