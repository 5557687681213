import { CardData, CardParsed, CharacterParsed } from "../../data/card.types";
import { CARDS_DB, CHARACTERS_DB } from "../../data/db";
import { GameState, PlayerChoice } from "../../data/game.types";
import Card from "../atoms/Card";

export interface DeckProps {
  cards: CardParsed[];
  cardsDict?: Record<string, CardParsed>;
  characters?: Record<string, CharacterParsed>;
  hintRotation?: boolean;
  isFirstEnter: boolean;
  isGameOver?: boolean;
  // onCardSwiped?: (card: CardParsed, playerChoice: PlayerChoice) => void;
  onDescentComplete?: (card: CardData, isTop: boolean) => void;
  onExitStart?: (card: CardData, playerChoice: PlayerChoice) => void;
  onExitRest?: (card: CardData, playerChoice: PlayerChoice) => void;
  onFlipComplete?: (card: CardData) => void;
  topCard: GameState['topCard'];
}

function Deck({ cards, cardsDict = CARDS_DB, characters = CHARACTERS_DB, hintRotation, isFirstEnter, isGameOver, onDescentComplete, onExitRest, onFlipComplete, topCard }: DeckProps) {

  // reverse so index 0 ends up painted last
  const reversedCards = topCard.id
    ? [cardsDict[topCard.id] ?? topCard.data, ...cards].reverse()
    : [...cards].reverse();

  return (
    <>
      {reversedCards.map((card, idx) => (
        <Card
          key={card.id}
          bearerIdx={topCard.bearerIdx}
          card={card}
          characters={characters}
          animateDownWithIndex={isFirstEnter ? idx + 1 : undefined}
          frontColor={isGameOver ? 'gray' : undefined}
          hintRotation={hintRotation}
          // last index in reversed array is top in state
          isDescended={card.id === topCard.id ? topCard.isDescended : !isFirstEnter}
          isFlipped={card.id === topCard.id && topCard.isFlipped}
          isRotatable={card.id === topCard.id && topCard.isRotatable}
          isTop={idx === reversedCards.length - 1}
          onDescentComplete={onDescentComplete}
          onExitRest={onExitRest}
          onFlipComplete={onFlipComplete}
        />
      ))}
    </>
  );
}

export default Deck
