import { animated, to as interpolate, useSpring } from "@react-spring/web";
import { useEffect } from "react";
import { CardData } from "../../data/card.types";
import styles from "./Card.module.css";

interface CardPositionSpringState {
  x: number;
  y: number;
  scale: number;
  rot: number;
}


interface Props {
  animateDownWithIndex?: number;
  card: CardData;
  isTop: boolean;
  onDescentComplete?: (card: CardData, isTop: boolean) => void;
  onReachTop?: (card: CardData) => void;
  // spring: SpringValues<CardPositionSpringState>;
  // makeEventHandlers: (cardId: string) => ReactDOMAttributes;
}

function CardDescender({ animateDownWithIndex, card, isTop, onDescentComplete, onReachTop }: Props): JSX.Element {

  const { x, y, rot, scale } = useSpring({
    from: animateDownWithIndex
      ? makeStartPosition(animateDownWithIndex)
      : undefined,
    to: INITIAL_SPRING_TO,
    delay: animateDownWithIndex ? (animateDownWithIndex + 10) * 100 : undefined,
    onRest: () => {
      onDescentComplete && onDescentComplete(card, isTop)

      if (isTop && onReachTop) {
        onReachTop(card);
      } 
    }
  });

  useEffect(() => {
    if (!animateDownWithIndex && isTop && onReachTop) {
      onReachTop(card);
    }
  }, [animateDownWithIndex, card, isTop, onReachTop]);


  return (
    <animated.div className={styles.cardContainer} style={{ x, y }}>
      <animated.div
        className={styles.cardRotator}
        style={{
          transform: interpolate([rot, scale], makeCSSTransformation),
          backgroundColor: "hsl(215, 100%, 20%)",
          opacity: 1,
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "row",
          padding: "10px 20px",
        }}
      >
        <div className={styles.cardContent}>
          <h2>
            RUNWAY
          </h2>
        </div>
      </animated.div>
    </animated.div>
  );
}

const INITIAL_SPRING_TO: CardPositionSpringState = {
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
};

const makeStartPosition = (idx: number): CardPositionSpringState => {
  return {
    ...INITIAL_SPRING_FROM,
    rot: -10 * idx,
    x: INITIAL_SPRING_FROM.x - 10 * idx,
  };
};

const INITIAL_SPRING_FROM: CardPositionSpringState = {
  x: -1000,
  y: -2000,
  rot: 0,
  scale: 1.5,
};

const makeCSSTransformation = (r: number, s: number) =>
  `perspective(1500px) rotateY(${
    r / 10
  }deg) rotateZ(${r}deg) scale(${s})`;


export default CardDescender
