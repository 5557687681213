import { Spinner } from "@chakra-ui/react";
import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";
// import Avatar, { AvatarStyle } from 'avataaars'
import { CardData } from "../../data/card.types";
import styles from "./Card.module.css";


interface Props {
  card: CardData;
  frontColor?: string;
  isFlipped: boolean;
  isTop: boolean;
  onFlipComplete?: (card: CardData, isFlipped: boolean) => void;
  onReachTop?: (card: CardData) => void;
}

function CardFlipper({ card, frontColor, isFlipped, onFlipComplete, isTop, onReachTop }: Props): JSX.Element {

  const [isTopReached, setIsTopReached] = useState(false);

  const { transform, flipStatus } = useSpring({
    flipStatus: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80, clamp: true },
    onRest: () => {
      onFlipComplete && onFlipComplete(card, isFlipped);
    },
  });

  useEffect(() => {
    if (isTop && onReachTop && !isTopReached) {
      onReachTop(card)
      setIsTopReached(true)
    }
  }, [card, isTop, isTopReached, onReachTop])

  return (
    <>
      <div className={styles.cardContainer}>
        <animated.div
          className={styles.cardRotator}
          style={{
            transform,
            opacity: flipStatus.to((o) => 1 - o),
            backgroundColor: "hsl(215, 100%, 20%)",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "row",
          }}
        >
          <div className={styles.cardContent}>
            <animated.h2
              style={{ opacity: flipStatus.to((o) => 1 - o), transform }}
            >
              RUNWAY
            </animated.h2>
          </div>
        </animated.div>
      </div>
      <div className={styles.cardContainer}>
        <animated.div
          className={styles.cardRotator}
          style={{
            opacity: flipStatus.to((v) => v),
            transform,
            rotateY: "180deg",
            backgroundColor: frontColor ?? "hsl(215, 100%, 80%)",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            alignItems: "row",
          }}
        >
          <div
            className={styles.cardContent}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* TODO: fill card front */}
            <Spinner size="xl" />
          </div>
        </animated.div>
      </div>
    </>
  );
}


export default CardFlipper
