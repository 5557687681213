import { IconContext } from "react-icons";
import { IconType } from "react-icons/lib";
import { animated, useSpring } from "@react-spring/web";
import { useState } from "react";

type Color = NonNullable<React.CSSProperties["color"]>;

interface Props {
  fillDecimal: number;
  colors?: [Color, Color];
  icon: IconType;
  id?: string;
  size?: number;
}

function DimensionIcon({
  colors: [emptyColor, fillColor] = ['lightgray', 'gray'],
  fillDecimal,
  icon: Icon,
  id = Icon.name,
  size = 64
}: Props): JSX.Element {

  const [iconColor, setIconColor] = useState(fillColor);

  const { fillPct } = useSpring({
    from: { fillPct: 0 },
    to: { fillPct: fillDecimal * 100  },
    config: {
      mass: 20,
      clamp: true
    },
    onStart: (result) => {
      if (result.value.fillPct > fillDecimal * 100) {
        setIconColor("red")
      } else if (result.value.fillPct < fillDecimal * 100) {
        setIconColor("green")
      }
    },
    onRest: () => {
      setIconColor(fillColor)
    }
  })

  return (
    <svg width={size} height={size}>
      <defs>
        <linearGradient id={id} gradientTransform="rotate(90)">
          <animated.stop offset={fillPct.to(v => `${100 - v}%`)} stopColor={emptyColor} />
          <animated.stop offset={`0%`} stopColor={emptyColor} />
          <animated.stop offset={`0%`} stopColor={iconColor} />
          <animated.stop offset={fillPct.to(v => `${v}%`)} stopColor={iconColor} />
        </linearGradient>
      </defs>
      <IconContext.Provider value={{ attr: { fill: `url('#${id}')` } }}>
        <Icon size={size} />
      </IconContext.Provider>
    </svg>
  );
}

export default DimensionIcon
