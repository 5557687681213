import { Expression } from 'jsep'
import { AccessoriesType, ClothesColor, ClothesType, EyebrowType, EyesType, FacialHairColor, FacialHairType, GraphicType, HairColor, HatColor, MouthType, SkinColor, TopType } from '../utils/avataaar.type';
import { Dimension, PlayerChoice } from './game.types';

export interface NotionCharacterEntry extends CharacterAppearance {
  id: string;
  role: string;
  status?: CharacterStatus;
  imageUrl?: string;
  name?: string;
  names?: string[];
  title?: string;
  cards?: string[];
}

export interface CharacterAppearance {
  topType?: TopType;
  accessoriesType?: AccessoriesType;
  hatColor?: HatColor;
  hairColor?: HairColor;
  facialHairType?: FacialHairType;
  facialHairColor?: FacialHairColor;
  clotheType?: ClothesType;
  clotheColor?: ClothesColor;
  graphicType?: GraphicType;
  eyeType?: EyesType;
  eyebrowType?: EyebrowType;
  mouthType?: MouthType;
  skinColor?: SkinColor;
}

export interface CharacterParsed {
  id: string;
  role: string;
  appearance: CharacterAppearance;
  status?: CharacterStatus;
  imageUrl?: string;
  name?: string;
  names?: string[];
  title?: string;
  cards?: string[];
}

export enum CharacterStatus {
  FIXED = 'fixed',
  ROTATING = 'rotating'
}

export interface NotionCardEntry {
  id: string;
  name: string;
  bearer?: string[];
  conditions?: string;
  storyline?: string[];
  variables?: string[];
  lockturn?: number;
  weight?: number;
  question: string;
  yes_override?: string;
  yes_answer?: string;
  yes_growth?: number;
  yes_people?: number;
  yes_cash?: number;
  yes_side_effects?: string;
  yes_successor?: string[];
  no_override?: string;
  no_answer?: string;
  no_growth?: number;
  no_people?: number;
  no_cash?: number;
  no_side_effects?: string;
  no_successor?: string[];
}

export interface Choice {
  override?: string;
  answer?: string;
  increment: Record<Dimension, number>;
  sideEffects: SideEffect[];
  successor?: string;
}

export interface SideEffect {
  variableName: string;
  operation: SideEffectOperation;
  value: any;
}

export enum SideEffectOperation {
  SET = '=',
  INCREMENT = '+='
}

export type CardData = CardParsed | CardAnswer;

export enum CardType {
  ORDINARY = 'CardParsed',
  ANSWER = 'CardAnswer'
}

export interface CardAnswer {
  id: string;
  parentId: string;
  parentChoice: PlayerChoice;
  type: CardType.ANSWER;
  text: string;
  successor?: string;
}

export interface CardParsed {
  id: string;
  type: CardType.ORDINARY;
  name: string;
  bearer: string[];
  condition: Expression;
  // storyline?: string[];
  // variables?: string[];
  lockturn: number;
  weight?: number;
  question: string;
  yes: Choice;
  no: Choice;
}