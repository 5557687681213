import { animated, to as interpolate, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { useRef } from "react";
import { Textfit } from 'react-textfit'
import { CardAnswer, CardData } from "../../data/card.types";
import { PlayerChoice } from "../../data/game.types";
import styles from "./Card.module.css";

interface SpringState {
  x: number;
  y: number;
  scale: number;
  rot: number;
}


interface CardStatus {
  isReadyToRemove: boolean;
  isRemoved: boolean;
  isTopReached: boolean;
}

interface Props {
  card: CardAnswer;
  frontColor?: string;
  isTop: boolean;
  onExitStart?: (card: CardData, playerChoice: PlayerChoice) => void;
  onExitRest?: (card: CardData, playerChoice: PlayerChoice) => void;
  // makeEventHandlers: (cardId: string) => ReactDOMAttributes;
}

const X_REMOVAL_THRESHOLD = 30;

function AnswerRotator({ card, frontColor, isTop, onExitStart, onExitRest }: Props): JSX.Element {

  const status = useRef<CardStatus>({
    isReadyToRemove: false,
    isRemoved: false,
    isTopReached: false,
  });

  const [spring, api] = useSpring(() => ({
    to: INITIAL_SPRING_TO,
    config: {
      clamp: true,
    },
    onRest: (animationResult) => {
      if (status.current.isRemoved) {
        const playerChoice: PlayerChoice =
          animationResult.value.x > 0 ? PlayerChoice.YES : PlayerChoice.NO;
        onExitRest && onExitRest(card, playerChoice);
      }
    },
  }));

  const makeEventHandlers = useDrag(
    ({
      active: isDragActive,
      movement: [xMovement],
      direction: [xDirection],
      velocity: [xVelocity],
    }) => {

      if (!isTop) return

      const isTriggerVelocity = xVelocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      if (isDragActive && isTriggerVelocity) {
        // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        status.current.isReadyToRemove = true;
      } else if (isDragActive && Math.abs(xMovement) > X_REMOVAL_THRESHOLD) {
        // also flag if it has moved far
        status.current.isReadyToRemove = true;
      }

      if (isDragActive && Math.abs(xMovement) < X_REMOVAL_THRESHOLD) {
        // unflag if repositioned closer
        status.current.isReadyToRemove = false;
      }
      if (!isDragActive && status.current.isReadyToRemove) {
        status.current.isRemoved = true;
      }

      api.start(() => {
        const { isRemoved } = status.current;
        const x = calculateX(isRemoved, isDragActive, xMovement);
        const rot = (isDragActive || isRemoved)
          ? xMovement / 100 + (isRemoved ? xDirection * 10 : 0)
          : 0; // How much the card tilts, flicking it harder makes it rotate faster
        const scale = isDragActive ? 1.1 : 1; // Active cards lift up a bit

        if (isRemoved) {
          const playerChoice = x > 0 ? PlayerChoice.YES : PlayerChoice.NO;
          onExitStart && onExitStart(card, playerChoice);
        }

        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: {
            friction: 50,
            tension: isRemoved ? 1000 : 500,
          },
        };
      });
    }
  );

  const {
    x,
    y,
    rot,
    scale,
  } = spring;

  return (
    <animated.div className={styles.cardContainer} style={{ x, y }}>
      <animated.div
        className={styles.cardRotator}
        style={{
          transform: interpolate([rot, scale], makeCSSTransformation),
          backgroundColor: frontColor ?? "hsl(215, 100%, 80%)",
          opacity: 1,
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "row",
        }}
        {...(isTop ? makeEventHandlers() : {})}
      >
        <div className={`${styles.cardContents} ${styles.cardFront}`}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: "20px",
              color: 'black',
              fontWeight: 600
            }}
          >
            <Textfit max={18}>{card.text}</Textfit>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
}

const INITIAL_SPRING_TO: SpringState = {
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
};

const calculateX = (
  isRemoved: boolean,
  isDragActive: boolean,
  xMovement: number
): number => {
  if (isRemoved) {
    // removed cards are placed off screen
    return xMovement > 0 ? window.innerWidth : -window.innerWidth;
  } else if (isDragActive) {
    // active cards are positioned as per movement
    return xMovement;
  } else {
    // inactive cards return central
    return 0;
  }
};

const makeCSSTransformation = (rotation: number, scale: number) =>
  `perspective(1500px) rotateY(${
    rotation
  }deg) rotateZ(${10*rotation}deg)`;


export default AnswerRotator
