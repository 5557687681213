import { CardData, CardParsed, CharacterParsed } from "./card.types";
import { CARDS_DB, CHARACTERS_DB } from "./db";

export enum Dimension {
  GROWTH = "growth",
  PEOPLE = "people",
  CASH = "cash"
}

export type DimensionScores = Record<Dimension, number>;

export enum PlayerChoice {
  YES = "yes",
  NO = "no",
}

export interface GameState {
  animations: {
    isFirstEnter: boolean;
    hintRotation: boolean;
  };
  characters: Record<string, CharacterParsed>;
  deck: {
    dictionary: Record<string, CardParsed>;
    locked: Record<string, number>;
  };
  topCard: {
    data?: CardData;
    // /** becomes defined when card is active */
    id?: string;
    isDescended: boolean;
    isFlipped: boolean;
    isRotatable: boolean;
    bearerIdx?: number;
  };
  variables: Record<string, any> & DimensionScores & { game_over: boolean; };
}

export const INITIAL_GAME_VARIABLES: GameState["variables"] = {
  [Dimension.GROWTH]: 50,
  [Dimension.PEOPLE]: 50,
  [Dimension.CASH]: 50,
  game_over: false
};


export const INITIAL_GAME_STATE: GameState = Object.freeze({
  animations: {
    isFirstEnter: true,
    hintRotation: true,
  },
  characters: CHARACTERS_DB,
  deck: {
    dictionary: CARDS_DB,
    locked: {},
  },
  topCard: {
    isDescended: false,
    isFlipped: false,
    isRotatable: false,
    bearerIdx: 0
  },
  variables: INITIAL_GAME_VARIABLES,
});
