import jsep, { Expression } from 'jsep';
import { CardAnswer, CardData, CardParsed, CardType, SideEffect, SideEffectOperation } from './card.types';
import { Dimension, GameState, PlayerChoice } from './game.types';
import { isConditionMet } from './jsep-utils';
import { sample } from 'lodash';

interface GameEndStatus {
  isOver: boolean;
  dimension?: Dimension;
  value?: 0 | 100;
}

export const pickNextCard = (prevCard: CardData, choice: PlayerChoice, deck: GameState['deck'], variables: GameState['variables']): CardData => {
  if (prevCard.type === CardType.ORDINARY && prevCard[choice].answer) {
    const answer: CardAnswer = {
      id: `${prevCard.id} > ${choice}`,
      parentId: prevCard.id,
      parentChoice: choice,
      type: CardType.ANSWER,
      text: prevCard[choice].answer!,
      successor: prevCard[choice].successor
    };
    return answer;
  }

  const successor =
    pickSuccessor(prevCard, choice, deck.dictionary);

  if (successor) return successor

  const gameEnd = gameEndStatus(variables);

  if (gameEnd.isOver) {
    const cardId = selectEndingId(gameEnd);
    const endCard = deck.dictionary[cardId];
    return endCard
  }

  return pickRandomValidCard(deck, variables)
}

export const pickRandomValidCard = (deck: GameState['deck'], variables: GameState['variables']): CardParsed => {
  const validCards = selectDeck(deck, variables);
  return sample(validCards)!
}

export const pickSuccessor = (prevCard: CardData, choice: PlayerChoice, deck: Record<string, CardParsed>): CardParsed | undefined => {
  let successor: string | undefined;
  if (prevCard.type === CardType.ORDINARY) {
    ({ successor } = prevCard[choice]);
  } else {
    ({ successor } = prevCard)
  }
  if (successor) {
    return deck[successor];
  }
}

export const processCardLocking = (
  deck: GameState['deck'],
  cardIdToLock?: string
): GameState['deck'] => {
  const locked = { ...deck.locked }
  for (let cardId in locked) {
    locked[cardId] > 1
      ? locked[cardId] -= 1
      : delete locked[cardId]
  }
  if (cardIdToLock && deck.dictionary[cardIdToLock]) {
    locked[cardIdToLock] = deck.dictionary[cardIdToLock].lockturn ?? 1;
  }
  return { ...deck, locked }
}

export const computeVariableEffects = (
  variableDict: GameState["variables"],
  card: CardParsed,
  choice: PlayerChoice
): GameState['variables'] => {
  const afterMainEffects = computeMainEffects(variableDict, card, choice);
  const sideEffects = card[choice].sideEffects;
  return computeSideEffects(afterMainEffects, sideEffects);
}

export const computeMainEffects = (
  variableDict: GameState["variables"],
  card: CardParsed,
  choice: PlayerChoice
): GameState["variables"] => {
  const variables = { ...variableDict };
  const { increment } = card[choice];
  for (let [key, valueChange] of Object.entries(increment)) {
    variables[key] += valueChange;
    if (variables[key] < 0) {
      variables[key] = 0;
    } else if (variables[key] > 100) {
      variables[key] = 100;
    }
  }
  return variables;
};

export const computeSideEffects = (
  variableDict: GameState["variables"],
  sideEffects: SideEffect[]
): GameState["variables"] => {
  const variables = { ...variableDict };
  for (let { variableName, operation, value } of sideEffects) {
    switch (operation) {
      case SideEffectOperation.INCREMENT:
        variables[variableName] = variables[variableName] ?? 0;
        variables[variableName] += value;
        break;
      case SideEffectOperation.SET:
        variables[variableName] = value;
        break;
      default:
        console.warn(`Unrecognised operation, ${operation}`);
    }
  }
  return variables;
};

export const computeVariableGrowth = (variableDict: GameState['variables']): GameState['variables'] => {

  const GROWTH_KEYWORD = '_growth'

  const variables = { ...variableDict }

  for (let variableName in variableDict) {
    if (variableName.endsWith(GROWTH_KEYWORD)) {
      const variableNameToGrow = variableName.replace(GROWTH_KEYWORD, '');
      const growthRate = variables[variableName];
      variables[variableNameToGrow] = variables[variableNameToGrow] ?? 0;
      variables[variableNameToGrow] += growthRate;
    }
  };
  return variables
}

export const gameEndStatus = (
  variables: GameState["variables"]
): GameEndStatus => {
  for (let dimension of Object.values(Dimension)) {
    if (variables[dimension] === 0 || variables[dimension] === 100) {
      return { isOver: true, dimension, value: variables[dimension] as 0 | 100 };
    }
  }

  return { isOver: false };
};

export const generateDummyDeck = (n: number, base: number = 1): CardParsed[] => {
  return [...Array(n).keys()].map((n) => ({
    id: n.toString() + base,
    type: CardType.ORDINARY,
    name: n.toString(),
    condition: jsep("true"),
    question: "loren ipsum",
    lockturn: 5,
    bearer: [],
    yes: {
      increment: { growth: 0, people: 0, cash: 0 },
      sideEffects: [],
    },
    no: {
      increment: { growth: 0, people: 0, cash: 0 },
      sideEffects: [],
    },
  }));
}

export const selectDeck = (deck: GameState['deck'], variables: GameState['variables']): CardParsed[] => {
  const cards = Object.values(deck.dictionary).filter(card => !deck.locked[card.id]);
  return selectValidByCondition(cards, variables)
}

const selectEndingId = ({ value, dimension }: GameEndStatus): string => {
  if (!dimension) throw new Error("Can't select ending without a dimension")
  if (typeof value !== "number") throw new Error("Can't select ending without an associated value")

  switch (dimension) {
    case Dimension.CASH:
      return value === 0
        ? "6c82e742-c20a-4b97-bf01-ec387c50d131"
        : "54027726-9fb0-49cf-a234-74b37afde00e";

    case Dimension.GROWTH:
      return value === 0
        ? "c7c024c4-002e-4faa-aa05-fe48203e3346"
        : "ee4136c0-1c3e-4243-a294-34a1666a8a3a";

    case Dimension.PEOPLE:
      return value === 0
        ? "cefc7266-afa5-4b5b-9bb5-b85c6c687a4b"
        : "f9fab2ec-435d-4e6e-bb0f-2db0b19bb201";
  }
}

export const selectValidByCondition = <T extends { condition: Expression }>(
  items: T[],
  variableDict: Record<string, any>
): T[] => {
  return items.filter(({ condition }) => isConditionMet(condition, variableDict))
}