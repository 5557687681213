import { Flex } from "@chakra-ui/react";
import { IoBarChart, IoPeople } from "react-icons/io5";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { Dimension, DimensionScores } from "../../data/game.types";
import DimensionIcon from "../atoms/DimensionIcon";

interface Props {
  values: DimensionScores;
}

function DimensionIcons({ values }: Props): JSX.Element {
  return (
    <Flex justifyContent='space-around'>
      <DimensionIcon
        id={Dimension.GROWTH}
        icon={IoBarChart}
        fillDecimal={values[Dimension.GROWTH] / 100}
      />
      <DimensionIcon
        id={Dimension.PEOPLE}
        icon={IoPeople}
        fillDecimal={values[Dimension.PEOPLE] / 100}
      />
      <DimensionIcon
        id={Dimension.CASH}
        icon={RiMoneyDollarBoxFill}
        fillDecimal={values[Dimension.CASH] / 100}
      />
    </Flex>
  );
}

export default DimensionIcons
