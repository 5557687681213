import { animated, to as interpolate, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { useEffect, useRef } from "react";
import { CardParsed, CharacterParsed } from "../../data/card.types";
import { PlayerChoice } from "../../data/game.types";
import styles from "./Card.module.css";
import { CHARACTERS_DB } from "../../data/db";
import { getCharacterImage } from "../../data/card-utils";
import CardRotatorText from "./CardRotatorText";

interface SpringState {
  x: number;
  y: number;
  scale: number;
  rot: number;
}

export const CARD_X_REMOVAL_THRESHOLD = 30;

interface CardStatus {
  isReadyToRemove: boolean;
  isRemoved: boolean;
  isTopReached: boolean;
}

interface Props {
  bearerIdx?: number;
  card: CardParsed;
  characters?: Record<string, CharacterParsed>;
  frontColor?: string;
  hintRotation?: boolean;
  isTop: boolean;
  onExitStart?: (card: CardParsed, playerChoice: PlayerChoice) => void;
  onExitRest?: (card: CardParsed, playerChoice: PlayerChoice) => void;
  // spring: SpringValues<SpringState>;
  // makeEventHandlers: (cardId: string) => ReactDOMAttributes;
}

const N_HINT_ROTATIONS = 4;

function CardRotator({ bearerIdx, card, characters = CHARACTERS_DB, frontColor, hintRotation, isTop, onExitStart, onExitRest }: Props): JSX.Element {

  const nHintRotationsLeft = useRef(hintRotation ? N_HINT_ROTATIONS : 0)

  const cardBearer = card.bearer[bearerIdx ?? 0];

  const bearerImage = characters[cardBearer].imageUrl || getCharacterImage(characters[cardBearer])

  const status = useRef<CardStatus>({
    isReadyToRemove: false,
    isRemoved: false,
    isTopReached: false,
  });

  const [spring, api] = useSpring(() => ({
    to: INITIAL_SPRING_TO,
    config: {
      clamp: true,
    },
    onRest: (animationResult) => {
      if (status.current.isRemoved) {
        const playerChoice: PlayerChoice =
          animationResult.value.x > 0 ? PlayerChoice.YES : PlayerChoice.NO;
        onExitRest && onExitRest(card, playerChoice);
      }
    },
  }));

  useEffect(() => {
    if (nHintRotationsLeft.current) {
      api.start(() => {
        return {
          to: animateRotationTo({
            isRemoved: false,
            isDragActive: true,
            xMovement: CARD_X_REMOVAL_THRESHOLD - 5,
            xDirection: 1,
          }),
          loop: () => {
            nHintRotationsLeft.current = Math.max(nHintRotationsLeft.current - 1, 0);
            if (nHintRotationsLeft.current === 0) {
              return { to: INITIAL_SPRING_TO }
            } else if (nHintRotationsLeft.current % 2 === 0) {
              return { to: HINT_ROTATION.right }
            } else {
              return { to: HINT_ROTATION.left }
            }
          },
          delay: 500,
          config: {
            friction: 40,
          },
        };
      });
    }
  });

  const makeEventHandlers = useDrag(
    ({
      active: isDragActive,
      movement: [xMovement],
      direction: [xDirection],
      velocity: [xVelocity],
    }) => {
      if (!isTop) return;

      nHintRotationsLeft.current = 0;

      const isTriggerVelocity = xVelocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      if (isDragActive && isTriggerVelocity) {
        // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        status.current.isReadyToRemove = true;
      } else if (isDragActive && Math.abs(xMovement) > CARD_X_REMOVAL_THRESHOLD) {
        // also flag if it has moved far
        status.current.isReadyToRemove = true;
      }

      if (isDragActive && Math.abs(xMovement) < CARD_X_REMOVAL_THRESHOLD) {
        // unflag if repositioned closer
        status.current.isReadyToRemove = false;
      }
      if (!isDragActive && status.current.isReadyToRemove) {
        status.current.isRemoved = true;
      }

      api.start(() => {
        const { isRemoved } = status.current;

        const to = animateRotationTo({ isRemoved, isDragActive, xDirection, xMovement })

        if (isRemoved) {
          const playerChoice = to.x > 0 ? PlayerChoice.YES : PlayerChoice.NO;
          onExitStart && onExitStart(card, playerChoice);
        }

        return {
          to,
          delay: undefined,
          config: {
            friction: 50,
            tension: isRemoved ? 1000 : 500,
          },
        };
      });
    }
  );

  const {
    x,
    y,
    rot,
    scale,
  } = spring;

  return (
    <animated.div className={styles.cardContainer} style={{ x, y }}>
      <animated.div
        className={styles.cardRotator}
        style={{
          transform: interpolate([rot, scale], makeCSSTransformation),
          backgroundColor: frontColor ?? "hsl(215, 100%, 80%)",
          opacity: 1,
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
          alignItems: "row",
        }}
        {...(isTop ? makeEventHandlers() : {})}
      >
        <div className={`${styles.cardContents} ${styles.cardFront}`}>
          <CardRotatorText {...{ card, x, rot }} />
          <img
            alt="pic"
            src={bearerImage}
            style={{
              maxHeight: "230px",
              // prevent image click interfering with rotation on desktop
              pointerEvents: 'none'
            }}
          />
        </div>
      </animated.div>
    </animated.div>
  );
}

const INITIAL_SPRING_TO: SpringState = {
  x: 0,
  y: 0,
  scale: 1,
  rot: 0,
};

const calculateX = (
  isRemoved: boolean,
  isDragActive: boolean,
  xMovement: number
): number => {
  if (isRemoved) {
    // removed cards are placed off screen
    return xMovement > 0 ? window.innerWidth : -window.innerWidth;
  } else if (isDragActive) {
    // active cards are positioned as per movement
    return xMovement;
  } else {
    // inactive cards return central
    return 0;
  }
};

const makeCSSTransformation = (rotation: number, scale: number) =>
  `perspective(1500px) rotateY(${
    rotation
  }deg) rotateZ(${10*rotation}deg)`;


interface AnimateRotationOpts {
  isRemoved: boolean;
  isDragActive: boolean;
  xDirection: number;
  xMovement: number;
}

const animateRotationTo = ({ isRemoved, isDragActive, xDirection, xMovement }: AnimateRotationOpts) => {
  const x = calculateX(isRemoved, isDragActive, xMovement);
  const rot =
    isDragActive || isRemoved
      ? xMovement / 100 + (isRemoved ? xDirection * 10 : 0)
      : 0; // How much the card tilts, flicking it harder makes it rotate faster
  const scale = isDragActive ? 1.1 : 1; // Active cards lift up a bit

  return {
    x,
    rot,
    scale,
  };
}

const HINT_ROTATION = {
  right: animateRotationTo({
    isRemoved: false,
    isDragActive: true,
    xMovement: CARD_X_REMOVAL_THRESHOLD - 5,
    xDirection: 1,
  }),
  left: animateRotationTo({
    isRemoved: false,
    isDragActive: true,
    xMovement: -(CARD_X_REMOVAL_THRESHOLD - 5),
    xDirection: -1,
  })
}

export default CardRotator
